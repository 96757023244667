import React from 'react'
import { ConfigData } from './newsletter.config'

const NewsletterConfiguration = () => {
  
  return (
    <>
    <p className="text-base font-semibold mx-4 my-2 px-2 py-2 bg-white rounded-lg border">Newsletter Configuration</p>
    <div className='mt-4'>
      <p className='px-2 py-2 text-right mx-4 bg-white rounded-lg border text-sm font-semibold'>User Email: {ConfigData.email}</p>
    </div>
    </>
  )
}

export default NewsletterConfiguration