export const ConfigData = {
    "email":"rvillanueva@seventwopartners.com",  
    "frequency_of_newsletter": 7,
    "auto_add_company_and_industry": false,
    "auto_add_industry": false,
    "on_email_and_plaform": true,
    "only_platform": false,
    "company_level_attributes": [
      "FUNDRAISE AND VALUATION",
      "MERGERS AND ACQUISITIONS",
      "PRODUCT RELATED",
      "PARTNERSHIPS",
      "EXECUTIVE APPOINTMENT AND CHANGES",
      "OFFICE OR GEOGRAPHY EXPANSION",
      "AWARDS AND RECOGNITIONS",
      "VENTURE INVESTMENTS",
      "LEADERSHIP INTERVIEW"
    ],
    "industry_level_attributes": [
      "Fundraise/valuation/Startup funding",
      "Mergers and Acquisitions/Investments/Grants",
      "Innovation/disruption",
      "Company deep-dive/ company product launches/ leadership interview",
      "Partnerships",
      "Sector case study/analysis/ future outlook/trend",
      "Regulatory",
      "Application areas/use cases/industry segments"
    ],
    "themes": [
      {
        "id": "ee3b0cdb-585f-44dd-a2cd-ad326de202fd",
        "name": "Partners Group",
        "isFocus": false,
        "isExpand": false,
        "time_saved": "Tue Aug 20 2024 18:45:37 GMT+0530 (India Standard Time)",
        "isEditingName": false,
        "company_watchlist": [
          {
            "permalink": "diversitech-9191",
            "name": "Diversitech"
          },
          {
            "permalink": "breitling",
            "name": "Breitling"
          },
          {
            "permalink": "idera",
            "name": "IDERA"
          },
          {
            "permalink": "apex-international-6845",
            "name": "Apex International"
          },
          {
            "permalink": "zabka-polska",
            "name": "Zabka Polska"
          },
          {
            "permalink": "srs-distribution-inc-2",
            "name": "SRS Distribution"
          },
          {
            "permalink": "precisely",
            "name": "Precisely"
          },
          {
            "permalink": "galderma",
            "name": "Galderma"
          },
          {
            "permalink": "unit",
            "name": "Unit"
          },
          {
            "permalink": "rovensa",
            "name": "Rovensa"
          },
          {
            "permalink": "atnorth",
            "name": "atNorth"
          },
          {
            "permalink": "epicor",
            "name": "Epicor"
          },
          {
            "permalink": "parexel",
            "name": "PAREXEL"
          }
        ],
        "intermidiate_name": "Partners Group",
        "selectedWatchList": "Company tag",
        "industry_watchlist": []
      },
      {
        "id": "94936d3e-d942-458b-8c24-d09d2a1ee22d",
        "name": "Leade Edge",
        "isFocus": false,
        "isExpand": false,
        "time_saved": "Tue Aug 20 2024 18:46:37 GMT+0530 (India Standard Time)",
        "isEditingName": false,
        "company_watchlist": [
          {
            "permalink": "artera-98e6",
            "name": "Artera"
          },
          {
            "permalink": "ebanx",
            "name": "EBANX"
          },
          {
            "permalink": "exagrid-systems",
            "name": "ExaGrid"
          },
          {
            "permalink": "gupshup-technology-india-pvt-ltd",
            "name": "GupShup"
          },
          {
            "permalink": "help-scout",
            "name": "Help Scout"
          },
          {
            "permalink": "holistiplan",
            "name": "Holistiplan"
          },
          {
            "permalink": "immedis",
            "name": "Immedis"
          },
          {
            "permalink": "lean-solutions",
            "name": "Lean Solutions"
          },
          {
            "permalink": "lucidchart",
            "name": "Lucid Software"
          },
          {
            "permalink": "material-bank",
            "name": "Material Bank"
          },
          {
            "permalink": "nearmap-com",
            "name": "Nearmap"
          },
          {
            "permalink": "reify-health",
            "name": "Reify Health"
          },
          {
            "permalink": "revenue-analytics-inc",
            "name": "Revenue Analytics"
          },
          {
            "permalink": "safesend-067d",
            "name": "SafeSend"
          },
          {
            "permalink": "trainwithpivot",
            "name": "Tempo"
          },
          {
            "permalink": "yousign",
            "name": "Yousign"
          },
          {
            "permalink": "pace-mate",
            "name": "Pace mate"
          },
          {
            "permalink": "growthzone",
            "name": "GrowthZone"
          },
          {
            "permalink": "launchdarkly",
            "name": "LaunchDarkly"
          },
          {
            "permalink": "liveview-technologies",
            "name": "LiveView Technologies"
          }
        ],
        "intermidiate_name": "Leade Edge",
        "selectedWatchList": "Company tag",
        "industry_watchlist": []
      },
      {
        "id": "d0a731ba-2e82-4eac-a3a1-acba51206b95",
        "name": "Light Street",
        "isFocus": false,
        "isExpand": false,
        "time_saved": "Tue Aug 20 2024 18:47:37 GMT+0530 (India Standard Time)",
        "isEditingName": false,
        "company_watchlist": [
          {
            "permalink": "toss",
            "name": "Toss"
          },
          {
            "permalink": "illumio",
            "name": "Illumio"
          },
          {
            "permalink": "scale-e680",
            "name": "Scale"
          },
          {
            "permalink": "contentful",
            "name": "Contentful"
          },
          {
            "permalink": "clari",
            "name": "Clari"
          }
        ],
        "intermidiate_name": "Light Street",
        "selectedWatchList": "Company tag",
        "industry_watchlist": []
      },
      {
        "id": "3f878210-b10f-4653-98a0-0bffc12e6b07",
        "name": "RCP Advisors",
        "isFocus": false,
        "isExpand": false,
        "time_saved": "Tue Aug 20 2024 18:48:37 GMT+0530 (India Standard Time)",
        "isEditingName": false,
        "company_watchlist": [
          {
            "permalink": "accurate-group",
            "name": "Accurate Group"
          },
          {
            "permalink": "infolink-d3fa",
            "name": "InfoLink"
          },
          {
            "permalink": "polara",
            "name": "Polara"
          },
          {
            "permalink": "kroll-bond-rating-agency",
            "name": "Kroll Bond Rating Agency"
          },
          {
            "permalink": "ncp-coatings",
            "name": "NCP Coatings"
          },
          {
            "permalink": "pavement-partners",
            "name": "Pavement Partners"
          },
          {
            "permalink": "autoagents",
            "name": "AutoAgents"
          },
          {
            "permalink": "pinchin",
            "name": "Pinchin"
          },
          {
            "permalink": "advantage-surgical-and-wound-care",
            "name": "Advantage Surgical and Wound Care"
          },
          {
            "permalink": "resonate-networks",
            "name": "Resonate"
          },
          {
            "permalink": "omeda",
            "name": "Omeda"
          },
          {
            "permalink": "siffron",
            "name": "Siffron"
          },
          {
            "permalink": "ordergroove",
            "name": "Ordergroove"
          },
          {
            "permalink": "unleashed-brands",
            "name": "Unleashed Brands"
          },
          {
            "permalink": "igs",
            "name": "IGS"
          },
          {
            "permalink": "wci-34cd",
            "name": "WCI"
          },
          {
            "permalink": "userzoom",
            "name": "UserZoom"
          },
          {
            "permalink": "spartan-fitness-holdings",
            "name": "Spartan Fitness Holdings"
          },
          {
            "permalink": "evantic",
            "name": "Evantic"
          },
          {
            "permalink": "puretech-scientific",
            "name": "PureTech Scientific"
          },
          {
            "permalink": "southwest-airlines",
            "name": "Southwest Airlines"
          },
          {
            "permalink": "american-landscape",
            "name": "American Landscape"
          },
          {
            "permalink": "cutispharma",
            "name": "Azurity Pharmaceuticals"
          }
        ],
        "intermidiate_name": "RCP Advisors",
        "selectedWatchList": "Company tag",
        "industry_watchlist": []
      },
      {
        "id": "1a6b7f1d-c68b-4bd5-b6f4-a2e919672c64",
        "name": "Tiger Global",
        "isFocus": false,
        "isExpand": true,
        "time_saved": "Tue Aug 20 2024 18:49:37 GMT+0530 (India Standard Time)",
        "isEditingName": false,
        "company_watchlist": [
          {
            "permalink": "1password",
            "name": "1Password"
          },
          {
            "permalink": "halodoc",
            "name": "Halodoc"
          },
          {
            "permalink": "6sense",
            "name": "6sense"
          },
          {
            "permalink": "a-team-acf0",
            "name": "A.Team"
          },
          {
            "permalink": "realityengines",
            "name": "Abacus.AI"
          },
          {
            "permalink": "ecsoglobal",
            "name": "Absolute"
          },
          {
            "permalink": "accrue-698f",
            "name": "Accrue"
          },
          {
            "permalink": "ai-rudder",
            "name": "AI Rudder"
          },
          {
            "permalink": "aleo-673f",
            "name": "Aleo"
          },
          {
            "permalink": "almanac-ce13",
            "name": "Almanac"
          },
          {
            "permalink": "amber-3",
            "name": "Amber"
          },
          {
            "permalink": "ambidextrous",
            "name": "Ambi Robotics"
          },
          {
            "permalink": "angellist",
            "name": "AngelList"
          },
          {
            "permalink": "apnaklub",
            "name": "ApnaKlub"
          },
          {
            "permalink": "aporia-technologies-ltd",
            "name": "Aporia"
          },
          {
            "permalink": "appwrite",
            "name": "Appwrite"
          },
          {
            "permalink": "arcadia-power-2",
            "name": "Arcadia"
          },
          {
            "permalink": "archipro",
            "name": "ArchiPro"
          },
          {
            "permalink": "attentive-ai",
            "name": "Attentive"
          },
          {
            "permalink": "authing",
            "name": "Authing"
          },
          {
            "permalink": "ascend-276c",
            "name": "Ascend"
          },
          {
            "permalink": "avataar-me",
            "name": "Avataar"
          },
          {
            "permalink": "ayopop",
            "name": "Ayoconnect"
          },
          {
            "permalink": "battery-smart",
            "name": "Battery Smart"
          },
          {
            "permalink": "bazaar-technologies",
            "name": "Bazaar Technologies"
          },
          {
            "permalink": "bego-5fd1",
            "name": "Bego"
          },
          {
            "permalink": "benchling",
            "name": "Benchling"
          },
          {
            "permalink": "benchsci",
            "name": "BenchSci"
          },
          {
            "permalink": "bharatpe",
            "name": "BharatPe"
          },
          {
            "permalink": "bizongo",
            "name": "Bizongo"
          },
          {
            "permalink": "blank-street",
            "name": "Blank Street"
          },
          {
            "permalink": "blockdaemon",
            "name": "Blockdaemon"
          },
          {
            "permalink": "bold-0f3e",
            "name": "Bold"
          },
          {
            "permalink": "box8-4750",
            "name": "Box8"
          },
          {
            "permalink": "bravado-network",
            "name": "Bravado"
          },
          {
            "permalink": "bttn",
            "name": "bttn"
          },
          {
            "permalink": "buf-technologies",
            "name": "Buf"
          },
          {
            "permalink": "built-robotics",
            "name": "Built Robotics"
          },
          {
            "permalink": "bvnk",
            "name": "BVNK"
          },
          {
            "permalink": "byrd",
            "name": "byrd"
          },
          {
            "permalink": "bytedance",
            "name": "ByteDance"
          },
          {
            "permalink": "calibrate-313f",
            "name": "Calibrate"
          },
          {
            "permalink": "captain-fresh",
            "name": "Captain Fresh"
          },
          {
            "permalink": "cargomatic",
            "name": "Cargomatic"
          },
          {
            "permalink": "carma-d03a",
            "name": "Carma"
          },
          {
            "permalink": "carrot-cart",
            "name": "Carrot"
          },
          {
            "permalink": "census-3975",
            "name": "Census"
          },
          {
            "permalink": "cents-ba2f",
            "name": "Cents"
          },
          {
            "permalink": "certik",
            "name": "CertiK"
          },
          {
            "permalink": "certora",
            "name": "Certora"
          },
          {
            "permalink": "cheq",
            "name": "CHEQ"
          },
          {
            "permalink": "cialfo-2",
            "name": "Cialfo"
          },
          {
            "permalink": "circle-2",
            "name": "Circle"
          },
          {
            "permalink": "xprep",
            "name": "Classplus"
          },
          {
            "permalink": "clickup",
            "name": "ClickUp"
          },
          {
            "permalink": "clicoh",
            "name": "Clicoh"
          },
          {
            "permalink": "cloudtrucks",
            "name": "CloudTrucks"
          },
          {
            "permalink": "cohere-82b8",
            "name": "Cohere"
          },
          {
            "permalink": "collibra",
            "name": "Collibra"
          },
          {
            "permalink": "commonwealth-fusion-systems",
            "name": "Commonwealth Fusion"
          },
          {
            "permalink": "connecteam",
            "name": "Connecteam"
          },
          {
            "permalink": "contentful",
            "name": "Contentful"
          },
          {
            "permalink": "cortex-intelligence",
            "name": "Cortex"
          },
          {
            "permalink": "cresta",
            "name": "Cresta"
          },
          {
            "permalink": "datacloak",
            "name": "Datacloak"
          },
          {
            "permalink": "dbt",
            "name": "DBT"
          },
          {
            "permalink": "dealshare",
            "name": "DealShare"
          },
          {
            "permalink": "demostack",
            "name": "Demostack"
          },
          {
            "permalink": "depict-ai",
            "name": "Depict"
          },
          {
            "permalink": "dian-xiaomi",
            "name": "Dian Xiaomi"
          },
          {
            "permalink": "diligent-board-member-services",
            "name": "Diligent"
          },
          {
            "permalink": "robotics-587d",
            "name": "ROBOTICS"
          },
          {
            "permalink": "dover-37be",
            "name": "Dover"
          },
          {
            "permalink": "duck-duck-go",
            "name": "DuckDuckGo"
          },
          {
            "permalink": "use-case-capital",
            "name": "Early Stage LA"
          },
          {
            "permalink": "electric-sheep-f22a",
            "name": "Electric Sheep"
          },
          {
            "permalink": "elementary-417d",
            "name": "Elementary"
          },
          {
            "permalink": "emerge-4695",
            "name": "Emerge"
          },
          {
            "permalink": "equip-4e53",
            "name": "Equip"
          },
          {
            "permalink": "facily",
            "name": "Facily"
          },
          {
            "permalink": "factorial",
            "name": "Factorial"
          },
          {
            "permalink": "fazz-financial",
            "name": "Fazz"
          },
          {
            "permalink": "flatfile",
            "name": "Flatfile"
          },
          {
            "permalink": "flex-9e13",
            "name": "Flex"
          },
          {
            "permalink": "journal-technologies-inc",
            "name": "Float"
          },
          {
            "permalink": "flock-safety",
            "name": "Flock Safety"
          },
          {
            "permalink": "flutterwave",
            "name": "Flutterwave"
          },
          {
            "permalink": "fort-robotics",
            "name": "FORT Robotics"
          },
          {
            "permalink": "forte-e22c",
            "name": "Forte"
          },
          {
            "permalink": "fractal-software",
            "name": "Fractal Software"
          },
          {
            "permalink": "genesis-c434",
            "name": "Genesis"
          },
          {
            "permalink": "geniemode",
            "name": "Geniemode"
          },
          {
            "permalink": "genki-forest",
            "name": "Genki Forest"
          },
          {
            "permalink": "goguardian",
            "name": "GoGuardian"
          },
          {
            "permalink": "great-expectations-c89a",
            "name": "Great Expectations"
          },
          {
            "permalink": "groyyo",
            "name": "Groyyo"
          },
          {
            "permalink": "grubmarket-inc",
            "name": "GrubMarket"
          },
          {
            "permalink": "guandata",
            "name": "Guandata"
          },
          {
            "permalink": "guardio",
            "name": "Guardio"
          },
          {
            "permalink": "harbinger-6b7e",
            "name": "Harbinger"
          },
          {
            "permalink": "helium-systems-inc",
            "name": "Helium"
          },
          {
            "permalink": "pt-higo-fitur-indonesia",
            "name": "HIGO"
          },
          {
            "permalink": "hitkey",
            "name": "HitPay"
          },
          {
            "permalink": "hive-7176",
            "name": "Hive"
          },
          {
            "permalink": "hivery",
            "name": "HIVERY"
          },
          {
            "permalink": "konekt-2",
            "name": "Hologram"
          },
          {
            "permalink": "humane",
            "name": "Humane"
          },
          {
            "permalink": "usehyphen",
            "name": "Hyphen"
          },
          {
            "permalink": "icon-plc",
            "name": "ICON"
          },
          {
            "permalink": "itilite",
            "name": "ITILITE"
          },
          {
            "permalink": "jellyfish-445f",
            "name": "Jellyfish"
          },
          {
            "permalink": "jianjia-robot",
            "name": "Jianjia Robot"
          },
          {
            "permalink": "jokr",
            "name": "JOKR"
          },
          {
            "permalink": "amica-financial-technologies",
            "name": "Jupiter"
          },
          {
            "permalink": "kandji",
            "name": "Kandji"
          },
          {
            "permalink": "kard",
            "name": "Kard"
          },
          {
            "permalink": "kavak",
            "name": "Kavak"
          },
          {
            "permalink": "deep-vision-c7a3",
            "name": "Kinara"
          },
          {
            "permalink": "klue",
            "name": "Klue"
          },
          {
            "permalink": "komodor",
            "name": "Komodor"
          },
          {
            "permalink": "lacework",
            "name": "Lacework"
          },
          {
            "permalink": "lifebit-ai",
            "name": "Lifebit"
          },
          {
            "permalink": "loadshare-networks",
            "name": "Loadshare Networks"
          },
          {
            "permalink": "lunar-bank",
            "name": "Lunar"
          },
          {
            "permalink": "m2p-fintech",
            "name": "M2P Fintech"
          },
          {
            "permalink": "marble-1db6",
            "name": "Marble"
          },
          {
            "permalink": "me-u",
            "name": "me&u"
          },
          {
            "permalink": "medable-inc",
            "name": "Medable"
          },
          {
            "permalink": "melio-0bc5",
            "name": "Melio"
          },
          {
            "permalink": "mensa-brands",
            "name": "Mensa Brands"
          },
          {
            "permalink": "metafy",
            "name": "Metafy"
          },
          {
            "permalink": "mojo-84ae",
            "name": "Mojo"
          },
          {
            "permalink": "moka",
            "name": "Moka"
          },
          {
            "permalink": "moloco",
            "name": "Moloco"
          },
          {
            "permalink": "moonpay",
            "name": "MoonPay"
          },
          {
            "permalink": "moov-technologies-inc",
            "name": "Moov"
          },
          {
            "permalink": "mos",
            "name": "Mos"
          },
          {
            "permalink": "mosscredit",
            "name": "Moss"
          },
          {
            "permalink": "moveworks",
            "name": "Moveworks"
          },
          {
            "permalink": "multiplier",
            "name": "Multiplier"
          },
          {
            "permalink": "nacelle",
            "name": "Nacelle"
          },
          {
            "permalink": "noah-medical",
            "name": "Noah Medical"
          },
          {
            "permalink": "the-not-company",
            "name": "NotCo"
          },
          {
            "permalink": "novi-f97c",
            "name": "NOVI"
          },
          {
            "permalink": "nowcoder",
            "name": "NOWCODER"
          },
          {
            "permalink": "nowports",
            "name": "Nowports"
          },
          {
            "permalink": "ntopology",
            "name": "nTopology"
          },
          {
            "permalink": "nuro-2",
            "name": "Nuro"
          },
          {
            "permalink": "nuvemshop-tiendanube",
            "name": "Nuvemshop"
          },
          {
            "permalink": "Nuvocargo",
            "name": "Nuvocargo"
          },
          {
            "permalink": "octoml",
            "name": "OctoAI"
          },
          {
            "permalink": "odeko",
            "name": "Odeko"
          },
          {
            "permalink": "ofbusiness",
            "name": "OfBusiness"
          },
          {
            "permalink": "on-deck-a441",
            "name": "On Deck"
          },
          {
            "permalink": "top-e6ff",
            "name": "Ontop"
          },
          {
            "permalink": "openai",
            "name": "OpenAI"
          },
          {
            "permalink": "openphone",
            "name": "OpenPhone"
          },
          {
            "permalink": "opensea",
            "name": "OpenSea"
          },
          {
            "permalink": "ophelia",
            "name": "Ophelia"
          },
          {
            "permalink": "opto-investments",
            "name": "Opto Investments"
          },
          {
            "permalink": "outschool",
            "name": "Outschool"
          },
          {
            "permalink": "ownbackup",
            "name": "OwnBackup"
          },
          {
            "permalink": "oxyzo",
            "name": "Oxyzo"
          },
          {
            "permalink": "papaya-global",
            "name": "Papaya Global"
          },
          {
            "permalink": "paycargo",
            "name": "PayCargo"
          },
          {
            "permalink": "pento",
            "name": "Pento"
          },
          {
            "permalink": "pinecone",
            "name": "Pinecone"
          },
          {
            "permalink": "pomelo-pay",
            "name": "Pomelo"
          },
          {
            "permalink": "portalone-inc",
            "name": "PortalOne"
          },
          {
            "permalink": "porter-0eee",
            "name": "Porter"
          },
          {
            "permalink": "productboard",
            "name": "Productboard"
          },
          {
            "permalink": "profishop-gmbh",
            "name": "PROFISHOP"
          },
          {
            "permalink": "progcap",
            "name": "Progcap"
          },
          {
            "permalink": "qualified",
            "name": "Qualified"
          },
          {
            "permalink": "quiknode",
            "name": "QuickNode"
          },
          {
            "permalink": "rapid-robotics",
            "name": "Rapid Robotics"
          },
          {
            "permalink": "raya",
            "name": "Raya"
          },
          {
            "permalink": "relationalai",
            "name": "RelationalAI"
          },
          {
            "permalink": "revolut",
            "name": "Revolut"
          },
          {
            "permalink": "rivery-io",
            "name": "Rivery.io"
          },
          {
            "permalink": "robust-intelligence",
            "name": "Robust Intelligence"
          },
          {
            "permalink": "rokt",
            "name": "ROKT"
          },
          {
            "permalink": "run-ai",
            "name": "Run:AI"
          },
          {
            "permalink": "safe-3c5f",
            "name": "Safe"
          },
          {
            "permalink": "scribehow",
            "name": "Scribe"
          },
          {
            "permalink": "Selfbook",
            "name": "Selfbook"
          },
          {
            "permalink": "sensors-data",
            "name": "Sensors Data"
          },
          {
            "permalink": "shelf-io",
            "name": "Shelf"
          },
          {
            "permalink": "side-5e5b",
            "name": "Side"
          },
          {
            "permalink": "skyhawk",
            "name": "Skyhawk"
          },
          {
            "permalink": "slicepay",
            "name": "slice"
          },
          {
            "permalink": "spendesk",
            "name": "Spendesk"
          },
          {
            "permalink": "spenmo",
            "name": "Spenmo"
          },
          {
            "permalink": "spinny",
            "name": "Spinny"
          },
          {
            "permalink": "graphcdn",
            "name": "Stellate"
          },
          {
            "permalink": "strongdm",
            "name": "StrongDM"
          },
          {
            "permalink": "superhuman",
            "name": "Superhuman"
          },
          {
            "permalink": "svt-robotics",
            "name": "SVT Robotics"
          },
          {
            "permalink": "swap-03c3",
            "name": "SWAP"
          },
          {
            "permalink": "tarabut-gateway",
            "name": "Tarabut"
          },
          {
            "permalink": "taxdoo",
            "name": "Taxdoo"
          },
          {
            "permalink": "tecton",
            "name": "Tecton"
          },
          {
            "permalink": "timescaledb",
            "name": "Timescale"
          },
          {
            "permalink": "torii-4618",
            "name": "Torii"
          },
          {
            "permalink": "tradesquare",
            "name": "TradeSquare"
          },
          {
            "permalink": "tradingview",
            "name": "TradingView"
          },
          {
            "permalink": "treble-30ee",
            "name": "Treble"
          },
          {
            "permalink": "trm-labs",
            "name": "TRM Labs"
          },
          {
            "permalink": "truelayer",
            "name": "TrueLayer"
          },
          {
            "permalink": "tul-302e",
            "name": "Tul"
          },
          {
            "permalink": "ula",
            "name": "Ula"
          },
          {
            "permalink": "vecna",
            "name": "Vecna"
          },
          {
            "permalink": "veho-technologies",
            "name": "Veho"
          },
          {
            "permalink": "veriff",
            "name": "Veriff"
          },
          {
            "permalink": "versatile-natures",
            "name": "VERSATILE"
          },
          {
            "permalink": "vivun-inc",
            "name": "Vivun"
          },
          {
            "permalink": "viz",
            "name": "Viz"
          },
          {
            "permalink": "waresix",
            "name": "waresix"
          },
          {
            "permalink": "wasoko",
            "name": "Wasoko"
          },
          {
            "permalink": "wati-263e",
            "name": "WATI"
          },
          {
            "permalink": "way-com",
            "name": "Way"
          },
          {
            "permalink": "weavr-9646",
            "name": "Weavr"
          },
          {
            "permalink": "webull-588b",
            "name": "Webull"
          },
          {
            "permalink": "enzyme-health",
            "name": "Wheel"
          },
          {
            "permalink": "wise-systems",
            "name": "Wise Systems"
          },
          {
            "permalink": "wiz-freight",
            "name": "Wiz Freight"
          },
          {
            "permalink": "wizehire",
            "name": "WizeHire"
          },
          {
            "permalink": "workato",
            "name": "Workato"
          },
          {
            "permalink": "worldcoin",
            "name": "Worldcoin"
          },
          {
            "permalink": "takeone",
            "name": "Wrapbook"
          },
          {
            "permalink": "xendit",
            "name": "Xendit"
          },
          {
            "permalink": "xentral",
            "name": "Xentral"
          },
          {
            "permalink": "yuga-labs",
            "name": "Yuga Labs"
          },
          {
            "permalink": "zipmoney-payments",
            "name": "Zip"
          },
          {
            "permalink": "cyber-armor-cc35",
            "name": "Cyber Armor"
          },
          {
            "permalink": "agora-system",
            "name": "Agora System"
          },
          {
            "permalink": "pixo-22df",
            "name": "PDAX | Philippine Digital Asset Exchange"
          },
          {
            "permalink": "resc",
            "name": "RESC"
          }
        ],
        "intermidiate_name": "Tiger Global",
        "selectedWatchList": "Company tag",
        "industry_watchlist": []
      }
    ]
}